const imageMetadata = {
  "photos/40webp/1.webp": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.67599722222222, 'longitude': 16.890272222222222},
  },
  "photos/40webp/2.webp": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.67744722222222, 'longitude': 16.89067777777778},
  },
  "photos/40webp/3.webp": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.67774722222222, 'longitude': 16.89328611111111},
  },
  "photos/40webp/4.webp": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.65091111111111, 'longitude': 16.897388888888887},
  },
  "photos/40webp/5.webp": {
    film: "Kodak Portra 160",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/40webp/6.webp": {
    film: "Kodak Portra 160",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.636830555555555, 'longitude': 16.940047222222223},
  },
  "photos/40webp/7.webp": {
    film: "Kodak Portra 800",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.65091111111111, 'longitude': 16.897388888888887},
  },
  "photos/40webp/8.webp": {
    film: "Kodak Portra 800",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/40webp/9.webp": {
    film: "Kodak Portra 800",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.65079444444444, 'longitude': 16.89665833333333},
  },
  "photos/40webp/10.webp": {
    film: "Kodak Portra 160",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: {'latitude': 52.366791666666664, 'longitude': 4.907997222222223},
  },
  "photos/40webp/11.webp": {
    film: "Kodak Portra 160",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: {'latitude': 52.23332777777778, 'longitude': 5.9457611111111115},
  },
  "photos/40webp/12.webp": {
    film: "Kodak Gold 200",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 52.23770277777778, 'longitude': 5.9460500000000005},
  },
  "photos/40webp/13.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.735508333333335, 'longitude': 16.928611111111113},
  },
  "photos/40webp/14.webp": {
    film: "Kodak Ultramax 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.83615833333334, 'longitude': 0.5660861111111112},
  },
  "photos/40webp/15.webp": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.82143888888889, 'longitude': 0.5589944444444445},
  },
  "photos/40webp/16.webp": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.82416666666667, 'longitude': 0.5583027777777778},
  },
  "photos/40webp/17.webp": {
    film: "Kono Delight Art 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: null,
  },
  "photos/40webp/18.webp": {
    film: "Kono Delight Art 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 48.88037222222222, 'longitude': 2.355011111111111},
  },
  "photos/40webp/19.webp": {
    film: "Kono Delight Art 400",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.21931666666667, 'longitude': 5.270366666666667},
  },
  "photos/40webp/20.webp": {
    film: "Kodak Ektachrome E100",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 44.84821388888889, 'longitude': 0.58045},
  },
  "photos/40webp/21.webp": {
    film: "Lomography Tungsten x64",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.22435833333333, 'longitude': 16.627275},
  },
  "photos/40webp/22.webp": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.127280555555554, 'longitude': 16.78293611111111},
  },
  "photos/40webp/23.webp": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.121052777777777, 'longitude': 16.775666666666666},
  },
  "photos/40webp/24.webp": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.52146388888889, 'longitude': 16.295075},
  },
  "photos/40webp/25.webp": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 28.127983333333333, 'longitude': 16.78278611111111},
  },
  "photos/40webp/26.webp": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.383783333333334, 'longitude': 4.8953999999999995},
  },
  "photos/40webp/27.webp": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.383827777777775, 'longitude': 4.895144444444444},
  },
  "photos/40webp/28.webp": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.3768, 'longitude': 4.889913888888889},
  },
  "photos/40webp/29.webp": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.36712777777778, 'longitude': 4.902177777777778},
  },
  "photos/40webp/30.webp": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.379025, 'longitude': 4.899911111111111},
  },
  "photos/40webp/31.webp": {
    film: "Kodak Ektachrome E100",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 52.402113888888884, 'longitude': 5.614341666666666},
  },
  "photos/40webp/32.webp": {
    film: "Agfa Foto LeBox",
    camera: "Agfa Foto LeBox",
    lens: null,
    location: {'latitude': 28.424961111111113, 'longitude': 14.057577777777778},
  },
  "photos/40webp/33.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/40webp/34.webp": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.82795277777778, 'longitude': 2.3688861111111112},
  },
  "photos/40webp/35.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/40webp/36.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/40webp/37.webp": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.83316111111112, 'longitude': 2.362741666666667},
  },
  "photos/40webp/38.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.636136111111114, 'longitude': 16.93265277777778},
  },
  "photos/40webp/39.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.63720277777778, 'longitude': 16.931030555555555},
  },
  "photos/40webp/40.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/40webp/41.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.63608611111111, 'longitude': 16.93131111111111},
  },
  "photos/40webp/42.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/40webp/43.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/40webp/44.webp": {
    film: "CineStill 800T",
    camera: "Pentax KX",
    lens: "50mm ƒ1.4",
    location: {'latitude': 52.369011111111114, 'longitude': 4.886513888888889},
  },
  "photos/40webp/45.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/40webp/46.webp": {
    film: "FujiFilm Provia 100f",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.674213888888886, 'longitude': 16.9024},
  },
  "photos/40webp/47.webp": {
    film: "Ilford Delta 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 52.786094444444444, 'longitude': 4.709305555555556},
  },
  "photos/40webp/48.webp": {
    film: "Ilford HP5 plus",
    camera: "Rollei 35 LED",
    lens: "40mm ƒ3.5",
    location: {'latitude': 48.84115, 'longitude': 2.320469444444444},
  },
  "photos/40webp/49.webp": {
    film: "Kodak Gold 200",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 52.782066666666665, 'longitude': 4.7276750000000005},
  },
  "photos/40webp/50.webp": {
    film: "Ilford HP5 plus",
    camera: "Rollei 35 LED",
    lens: "40mm ƒ3.5",
    location: {'latitude': 44.82143888888889, 'longitude': 0.5589944444444445},
  },
  "photos/40webp/51.webp": {
    film: "Ilford HP5 plus",
    camera: "Rollei 35 LED",
    lens: "40mm ƒ3.5",
    location: {'latitude': 44.824513888888895, 'longitude': 0.5575527777777778},
  },
  "photos/40webp/52.webp": {
    film: "CineStill 400D",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.88428888888889, 'longitude': 2.3413416666666667},
  },
  "photos/40webp/53.webp": {
    film: "CineStill 400D",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.85138055555556, 'longitude': 2.3268361111111107},
  },
  "photos/40webp/54.webp": {
    film: "CineStill 400D",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.81595833333333, 'longitude': 2.1077777777777778},
  },
  "photos/40webp/55.webp": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.635983333333336, 'longitude': 16.943963888888888},
  },
  "photos/40webp/56.webp": {
    film: "FujiFilm C200",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.861019444444445, 'longitude': 2.335863888888889},
  },
  "photos/40webp/57.webp": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/40webp/58.webp": {
    film: "FujiFilm C200",
    camera: "Canon EOS 500N",
    lens: "35-80mm ƒ4-5.6",
    location: {'latitude': 48.86101388888889, 'longitude': 2.3358527777777778},
  },
  "photos/40webp/59.webp": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.635327777777775, 'longitude': 16.93995},
  },
  "photos/40webp/60.webp": {
    film: "Kodak Portra 400",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.736108333333334, 'longitude': 16.92508888888889},
  },
  "photos/40webp/61.webp": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/40webp/62.webp": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.84861388888889, 'longitude': 17.153480555555554},
  },
  "photos/40webp/63.webp": {
    film: "Kodak Ektar 100",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.66863333333333, 'longitude': 17.044400000000003},
  },
  "photos/40webp/64.webp": {
    film: "Kodak Ektar 100",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.66863333333333, 'longitude': 17.044400000000003},
  },
  "photos/40webp/65.webp": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.75988888888889, 'longitude': 16.943241666666665},
  },
  "photos/40webp/66.webp": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: null,
  },
  "photos/40webp/67.webp": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.6429, 'longitude': 16.916313888888887},
  },
  "photos/40webp/68.webp": {
    film: "FujiFilm C200",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: null,
  },
  "photos/40webp/69.webp": {
    film: "Reflx Lab 400D",
    camera: "Pentax KX",
    lens: "35mm ƒ2",
    location: {'latitude': 32.64287777777778, 'longitude': 16.91616111111111},
  },
  "photos/40webp/70.webp": {
    film: "Kodak Ektar 100",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.63408333333333, 'longitude': 16.944197222222222},
  },
  "photos/40webp/71.webp": {
    film: "Kodak Portra 160",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.6333, 'longitude': 16.945308333333333},
  },
  "photos/40webp/72.webp": {
    film: "Kodak Portra 160",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: {'latitude': 32.638558333333336, 'longitude': 16.950180555555555},
  },
  "photos/40webp/73.webp": {
    film: "FujiFilm C200",
    camera: "Praktica Super TL1000",
    lens: "28mm ƒ2.8",
    location: {'latitude': 48.58834743488439, 'longitude': 9.470053644563311},
  },
  "photos/40webp/74.webp": {
    film: "Kodak Portra 400",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: null,
  },
  "photos/40webp/75.webp": {
    film: "Kodak Portra 400",
    camera: "Mamiya 645",
    lens: "80mm ƒ2.8",
    location: null,
  },
  "photos/40webp/76.webp": {
    film: "Kodak Gold 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/77.webp": {
    film: "FujiFilm C200",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: null,
  },
  "photos/40webp/78.webp": {
    film: "FujiFilm C200",
    camera: "Minolta Panorama Zoom 28",
    lens: null,
    location: null,
  },
  "photos/40webp/79.webp": {
    film: "Kodak Gold 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/80.webp": {
    film: "Kodak Gold 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/81.webp": {
    film: "Kodak ColorPlus 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/82.webp": {
    film: "Kodak ColorPlus 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/83.webp": {
    film: "Kodak ColorPlus 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/84.webp": {
    film: "Kodak ColorPlus 200",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/85.webp": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/86.webp": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
  "photos/40webp/87.webp": {
    film: "Kodak Portra 400",
    camera: "Canon EOS 500N",
    lens: "50mm f1.8",
    location: null,
  },
};

export default imageMetadata;
